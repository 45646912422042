.lgyrlz {
  background-color: transparent !important;
}
.hhFJlm {
  background-color: #161522 !important;
}
.jnvoRm {
  color: #fff !important;
}
.gTcqlT {
  fill: #fff !important;
}
.hZVDbF {
  color: #fff !important;
}
.iGdKqv {
  border-color: #fff !important;
  box-shadow: none;
  color: #fff !important;
}
.heKKwK {
  border-color: #fff !important;
  box-shadow: none;
  color: #fff !important;
}
.eUyVkd,
.jFIScR,
.fsSeeb,
.jibsak,
.hFUUvd {
  background-color: #161522 !important;
  color: #fff !important;
}
.evumGo {
  background-color: #161522 !important;
}
.hsqAaa {
  display: inline !important;
}
.iONwHy {
  width: unset !important;
}
.gcQKnf {
  fill: #fff !important;
}
.iZJBPy {
  fill: #fff !important;
}
.jRNEBu {
  color: #fff !important;
}
.eSvlCC {
  color: #fff !important;
}
.JTniw {
  background-color: transparent !important;
}
.dMyrsm {
  background-color: transparent !important;
}
.kChkRq {
  background-color: transparent !important;
}
.jWkMFN {
  fill: #fff !important;
}
.gTGzfO {
  fill: #fff !important;
}
.hufIwr {
  fill: #fff !important;
}
.jJOcej {
  color: #fff !important;
}
.bsLwMN {
  background-color: transparent !important;
}
.gEUBpy {
  background-color: transparent !important;
}
.jjEZxv {
  background-color: transparent !important;
}
.cacumS {
  background-color: transparent !important;
}
.eUVghC {
  background-color: transparent !important;
}
.jvEWoM {
  color: #fff !important;
}
.kvjDUB {
  color: #fff !important;
}
.kofeUK {
  color: #fff !important;
}
.dqmfMZ {
  color: #fff !important;
}
.gfaeWj {
  fill: #fff !important;
}

@media (max-width: 970px) {
  .dMyrsm {
    background-color: #171798 !important ;
  }
  .kChkRq {
    background-color: #171798 !important;
  }
}

@media (max-width: 480px) {
  .dMyrsm {
    background-color: #171798 !important;
  }
  .kChkRq {
    background-color: #171798 !important;
  }
}
@media (max-width: 768px) {
  .dMyrsm {
    background-color: #171798 !important;
  }
  .kChkRq {
    background-color: #171798 !important;
  }
}
